import { graphql, useStaticQuery } from "gatsby"

export const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                wpMenu {
                    menuItems {
                        nodes {
                            id
                            url
                            label
                        }
                    }
                }
                wp {
                    header {
                        acf {
                            followUs
                        }
                    }
                    socials {
                        acf {
                            facebook
                            instagram
                            twitter
                            youtube
                        }
                    }
                }
            }
        `
    )
    return {
        menuItems: data.wpMenu.menuItems.nodes,
        followText: data.wp.header.acf.followUs,
        socials: {
            facebook: data.wp.socials.acf.facebook,
            instagram: data.wp.socials.acf.instagram,
            twitter: data.wp.socials.acf.twitter,
            youtube: data.wp.socials.acf.youtube,
        },
    }
}
