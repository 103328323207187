import React, { useState, useEffect, useRef } from "react"
import throttle from "lodash.throttle"
import styled from "styled-components"

import { Link } from "gatsby"
import { useQuery } from "./useQuery"
import { breakpoints } from "@breakpoints"
import Logo from "@icons/logo-v3.svg"

import SocialBanner from "./SocialBanner"
import Hamburger from "./Hamburger"
import Menu from "./Menu"
import Icons from "./Icons"
import { GridContainer } from "../../styled/GridContainer"

const StyledHeader = styled.header<{ scrolled: boolean; transparent: boolean }>`
    background-color: ${({ transparent }) =>
        transparent ? "transparent " : "var(--dark-blue)"};
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    transition: 0.3s;
    transform: ${({ scrolled }) => (scrolled ? "translateY(-40px)" : "none")};

    ${breakpoints.md} {
        height: auto;
    }
`

const StyledWrapper = styled.div<{ scrolled: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.6s cubic-bezier(0.55, 0.17, 0.05, 0.85);
    will-change: height;
    height: ${({ scrolled }) => (!scrolled ? "135px" : "100px")};
`
const StyledLogolink = styled(props => <Link {...props} />)`
    width: 170px;

    ${breakpoints.md} {
        width: 300px;
    }

    svg {
        width: 100%;
    }
`

const Header = ({ frontPage }: props) => {
    const [active, setActive] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [isScrolling, setIsScrolling] = useState(false)
    const [transparent, setTransparent] = useState(frontPage)
    const prevPosition = useRef(scrollPosition)

    const { followText, socials, menuItems } = useQuery()

    const toggleMenu = () => {
        setActive(!active)
        if (!active) {
            document.body.classList.add("body-overflow")
        } else {
            document.body.classList.remove("body-overflow")
        }

        if (frontPage) {
            if (transparent || window.pageYOffset > 0) {
                setTransparent(false)
            } else {
                setTransparent(true)
            }
        }
    }

    useEffect(() => {
        const calcHeight = throttle(() => {
            const currentScroll = window.pageYOffset
            const IsScrolling = prevPosition.current < currentScroll
            prevPosition.current = window.pageYOffset

            if (currentScroll > 0) {
                setTransparent(false)
            } else if (frontPage) {
                setTransparent(true)
            }

            setIsScrolling(IsScrolling)
            setScrollPosition(currentScroll)
        }, 500)
        window.addEventListener("scroll", calcHeight)
        return () => {
            window.removeEventListener("scroll", calcHeight)
        }
    }, [])

    useEffect(() => {
        if (active) {
            document.body.classList.add("body-overflow")
        } else {
            document.body.classList.remove("body-overflow")
        }
    }, [active])

    return (
        <StyledHeader transparent={transparent} scrolled={isScrolling}>
            <div style={{ position: "relative" }}>
                <SocialBanner followText={followText} links={socials} />
                <GridContainer>
                    <StyledWrapper scrolled={isScrolling}>
                        <StyledLogolink
                            aria-label="Link do strony głównej"
                            to="/"
                        >
                            <Logo />
                        </StyledLogolink>
                        <Menu active={active} menuItems={menuItems} />
                        <Icons />
                        <Hamburger
                            active={active}
                            onClick={() => {
                                toggleMenu()
                                setIsScrolling(false)
                            }}
                            text="Menu"
                        />
                    </StyledWrapper>
                </GridContainer>
            </div>
        </StyledHeader>
    )
}

export default Header

interface props {
    frontPage: boolean
}
