import React from "react"
import styled from "styled-components"
import { Header } from "./styled"

const StyledWrapper = styled.div`
    margin-top: 20px;
`

const StyledButton = styled.a`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-weight: 700;
    font-size: var(--base-font);
    width: 150px;
    height: 35px;
    background-color: var(--red);
    border-radius: 10px;

    &:first-of-type {
        margin-right: 20px;
    }
`

const EmergencyButtons = ({ header, numbers }: props) => {
    return (
        <StyledWrapper>
            <Header dangerouslySetInnerHTML={{ __html: header }} />
            {numbers?.map(({ emergencyNumber }) => (
                <StyledButton
                    key={emergencyNumber}
                    href={`tel:${emergencyNumber}`}
                >
                    {emergencyNumber}
                </StyledButton>
            ))}
        </StyledWrapper>
    )
}

export default EmergencyButtons

interface props {
    header: string
    numbers: Array<{ emergencyNumber: string }>
}
