import styled from "styled-components"
import { breakpoints } from "@breakpoints"

export const GridContainer = styled.div`
    max-width: var(--grid-width);
    padding: 0 20px;
    margin: 0 auto;

    ${breakpoints.lg} {
        padding: 0 40px;
    }
`
