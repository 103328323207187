import React from "react"
import styled from "styled-components"
import ProfileButton from "./ProfileButton"
import CartButton from "./CartButton"

const StyledWrapper = styled.div`
    display: flex;
    margin-left: auto;
    position: relative;
`

const Icons = () => {
    return (
        <StyledWrapper>
            <CartButton />
            <ProfileButton />
        </StyledWrapper>
    )
}

export default Icons
