import React from "react"
import styled from "styled-components"
import { GridContainer } from "../../styled/GridContainer"

const StyledWrapper = styled.div`
    background-color: var(--very-dark-blue);
    padding: 10px 0;
    width: 100%;
    font-size: var(--base-font);

    a {
        color: var(--light-blue);
        font-weight: 700;
    }
`

const CopyrightBar = ({ text }: props) => {
    return (
        <StyledWrapper>
            <GridContainer
                dangerouslySetInnerHTML={{ __html: text }}
            ></GridContainer>
        </StyledWrapper>
    )
}

export default CopyrightBar

interface props {
    text: string
}
