import React from "react"
import styled from "styled-components"
import { Header } from "./styled"

import Facebook from "@icons/facebook-white.svg"
import Twitter from "@icons/twitter-white.svg"
import Youtube from "@icons/youtube-white.svg"
import Instagram from "@icons/instagram-white.svg"

const StyledWrapper = styled.a`
    display: flex;
    align-items: center;
    margin: 20px 35px 30px 0;
`

const StyledIconsWrapper = styled.div`
    display: flex;
`

const StyledText = styled.span`
    margin-left: 10px;
    font-size: var(--medium-font);
`

const Socials = ({ facebook, instagram, youtube, twitter, header }: props) => {
    return (
        <div>
            <Header dangerouslySetInnerHTML={{ __html: header }} />
            <StyledIconsWrapper>
                <div>
                    <StyledWrapper
                        rel="noopener"
                        target="_blank"
                        href={facebook}
                    >
                        <Facebook />
                        <StyledText
                            dangerouslySetInnerHTML={{ __html: "Facebook" }}
                        />
                    </StyledWrapper>
                    <StyledWrapper
                        rel="noopener"
                        target="_blank"
                        href={instagram}
                    >
                        <Instagram />
                        <StyledText
                            dangerouslySetInnerHTML={{ __html: "Instagram" }}
                        />
                    </StyledWrapper>
                </div>
                <div>
                    <StyledWrapper
                        rel="noopener"
                        target="_blank"
                        href={youtube}
                    >
                        <Youtube />
                        <StyledText
                            dangerouslySetInnerHTML={{ __html: "Youtube" }}
                        />
                    </StyledWrapper>
                    <StyledWrapper
                        rel="noopener"
                        target="_blank"
                        href={twitter}
                    >
                        <Twitter />
                        <StyledText
                            dangerouslySetInnerHTML={{ __html: "Twitter" }}
                        />
                    </StyledWrapper>
                </div>
            </StyledIconsWrapper>
        </div>
    )
}

export default Socials

interface props {
    facebook: string
    instagram: string
    youtube: string
    twitter: string
    header: string
}
