import { graphql, useStaticQuery } from "gatsby"

export const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    footer {
                        acf {
                            copyright
                            emergencyHeader
                            emergencyNumber {
                                emergencyNumber
                            }
                            fastContact
                            linksHeader
                            linksMenu {
                                external
                                link
                                text
                            }
                            menu {
                                link
                                text
                                external
                            }
                            menuHeader
                            socialsHeader
                        }
                    }
                    socials {
                        acf {
                            facebook
                            instagram
                            twitter
                            youtube
                        }
                    }
                }
            }
        `
    )
    return data.wp
}
