import React from "react"
import styled from "styled-components"

import { GridContainer } from "../../styled/GridContainer"

import Facebook from "@icons/facebook.svg"
import Twitter from "@icons/twitter.svg"
import Youtube from "@icons/youtube.svg"
import Instagram from "@icons/instagram.svg"

const StyledWrapperOuter = styled.div`
    background-color: #fff;
    width: 100%;
    transition: 0.3s;
`
const StyledWrapperInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
`

const StyledIcon = styled.a`
    margin: 0 10px;

    &:last-child {
        margin-right: 0;
    }
`

const StyledText = styled.span`
    color: var(--dark-blue);
    font-weight: 700;
    margin-right: 20px;
`

const SocialBanner = ({ followText, links }: props) => {
    const socials = [
        { icon: <Facebook />, link: links.facebook },
        { icon: <Twitter />, link: links.twitter },
        { icon: <Youtube />, link: links.youtube },
        { icon: <Instagram />, link: links.instagram },
    ]
    return (
        <StyledWrapperOuter>
            <GridContainer>
                <StyledWrapperInner>
                    <StyledText
                        dangerouslySetInnerHTML={{ __html: followText }}
                    />
                    {socials.map(({ icon, link }) => (
                        <StyledIcon
                            key={link}
                            aria-label={link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={link}
                        >
                            {icon}
                        </StyledIcon>
                    ))}
                </StyledWrapperInner>
            </GridContainer>
        </StyledWrapperOuter>
    )
}

export default SocialBanner

interface props {
    followText: string
    links: {
        facebook: string
        twitter: string
        youtube: string
        instagram: string
    }
}
