import React from "react"
import styled from "styled-components"

import { breakpoints } from "@breakpoints"

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: #fff;
    margin-left: 20px;

    ${breakpoints.md} {
        display: none;
    }
`

const StyledPart = styled.span<{ active: boolean }>`
    width: 25px;
    height: 2px;
    margin: 2px 0;
    background: #fff;
    margin-left: auto;
    transition: 0.3s;

    &:nth-child(1) {
        transform: ${({ active }) =>
            active ? "rotate(45deg) translate(9px)" : "rotate(0deg)"};
        margin-left: ${({ active }) => (active ? "-10px" : "auto")};
    }

    &:nth-child(2) {
        width: 20px;
        opacity: ${({ active }) => (active ? "0" : "1")};
        transform: ${({ active }) => (active ? "translateX(20px)" : "none")};
    }

    &:nth-child(3) {
        transform: ${({ active }) =>
            active ? "rotate(-45deg) translate(8px)" : "rotate(0deg)"};
        margin-left: ${({ active }) => (active ? "-10px" : "auto")};
    }
`

const StyledText = styled.span`
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.04em;
    margin-top: 2px;
`

const Hamburger = ({ text, active, onClick }: props) => {
    return (
        <StyledWrapper onClick={onClick}>
            <StyledPart active={active} />
            <StyledPart active={active} />
            <StyledPart active={active} />
            <StyledText dangerouslySetInnerHTML={{ __html: text }} />
        </StyledWrapper>
    )
}

export default Hamburger

interface props {
    text: string
    active: boolean
    onClick: () => void
}
