import React, { lazy, Suspense } from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { isBrowser } from "../../utils/isBrowser"

import Header from "./header/Header"
import Footer from "./footer/Footer"
import Modal from "../common/Modal"
const LoginRegisterForm = lazy(
    () => import("../common/login-register-form/LoginRegisterForm")
)

const StyledMain = styled.main`
    margin-top: 200px;

    ${breakpoints.md} {
        margin-top: 220px;
    }
`

const Template = ({ children, isFrontPage }: props) => {
    return (
        <StyledMain>
            <Header frontPage={isFrontPage} />
            {children}
            <Footer />
            <Modal>
                {isBrowser() && (
                    <Suspense fallback="wczytywanie">
                        <LoginRegisterForm />
                    </Suspense>
                )}
            </Modal>
        </StyledMain>
    )
}

export default Template

interface props {
    children: React.ReactNode
    isFrontPage: boolean
}
