import React, { useContext } from "react"
import { UserContext } from "../../context/UserContext"
import { ModalContext } from "../../context/ModalContext"
import styled from "styled-components"
import { Link } from "gatsby"
import { breakpoints } from "@breakpoints"
import { routes } from "@routes"
import User from "@icons/user.svg"

const StyledUser = styled(({ mobile, ...props }) => <User {...props} />)`
    display: ${({ mobile }) => (mobile ? "inline" : "none")};
    cursor: pointer;

    ${breakpoints.md} {
        display: ${({ mobile }) => (mobile ? "none" : "inline")};
    }
`

const ProfileButton = ({ mobile }: props) => {
    const { userData } = useContext<any>(UserContext)
    const { setModalActive, setNavigateAfterClosing } =
        useContext<any>(ModalContext)
    return (
        <div>
            {userData ? (
                <Link to={routes.userProfile}>
                    <StyledUser data-cy="profileUserButton" mobile={mobile} />
                </Link>
            ) : (
                <div
                    onClick={() => {
                        setNavigateAfterClosing({ href: routes.userProfile })
                        setModalActive(true)
                    }}
                >
                    <StyledUser
                        data-cy="loginRegisterFormButton"
                        mobile={mobile}
                    />
                </div>
            )}
        </div>
    )
}

export default ProfileButton

interface props {
    mobile?: boolean
}
