import styled from "styled-components"
import { breakpoints } from "@breakpoints"

export const H1 = styled.h1`
    font-size: var(--xlarge-font);
    font-weight: 700;
    line-height: 1.1;

    ${breakpoints.md} {
        font-size: 70px;
    }
`

export const H2 = styled.h2`
    font-size: var(--big-font);
    font-weight: 700;
    color: var(--dark-blue);
    line-height: 1.5;

    ${breakpoints.md} {
        font-size: 50px;
    }
`

export const H3 = styled.h3<{ small?: boolean }>`
    font-weight: 600;
    font-size: var(--small-font);
    color: var(--gray);
    text-transform: uppercase;
    letter-spacing: 0.1em;

    ${breakpoints.md} {
        font-size: ${({ small }) =>
            small ? "var(--base-font)" : "var(--medium-font)"};
    }
`

export const H4 = styled.h4<propsh4>`
    font-size: var(--medium-font);
    font-weight: 700;
    color: ${({ white }) => (white ? "#fff" : "var(--dark-blue)")};

    ${breakpoints.md} {
        font-size: ${({ small }) =>
            small ? "var(--medium-font)" : "var(--large-font)"};
    }
`

export const H5 = styled.h5`
    font-size: var(--base-font);
    font-weight: 700;
    color: var(--light-blue);
    text-transform: uppercase;
`

interface propsh4 {
    white?: boolean
    small?: boolean
}
