import React from "react"
import { Link } from "gatsby"
import { breakpoints } from "@breakpoints"
import styled from "styled-components"
import { Header } from "./styled"

const StyledWrapper = styled.div`
    &:first-of-type {
        margin-right: 40px;

        ${breakpoints.lg} {
            margin-right: 120px;
        }
    }
`

const StyledMenu = styled.nav`
    display: flex;
    flex-direction: column;
`

const StyledMenuItem = styled.span`
    font-size: var(--base-font);
    margin-top: 10px;
`

const selectLink = (link: string, text: string, external: boolean) => {
    if (external) {
        return <a href={link} dangerouslySetInnerHTML={{ __html: text }} />
    } else {
        return <Link to={link} dangerouslySetInnerHTML={{ __html: text }} />
    }
}

const Menu = ({ header, items }: props) => {
    return (
        <StyledWrapper>
            <Header dangerouslySetInnerHTML={{ __html: header }} />
            <StyledMenu>
                {items?.map(({ link, text, external }: singleItem) => (
                    <StyledMenuItem key={text}>
                        {selectLink(link, text, external)}
                    </StyledMenuItem>
                ))}
            </StyledMenu>
        </StyledWrapper>
    )
}

export default Menu

interface singleItem {
    text: string
    link: string
    external: boolean
}

interface props {
    header: string
    items: Array<singleItem>
}
