import { graphql, useStaticQuery } from "gatsby"
import { gatsbyImage } from "@types"

export const useBasicQuery = () => {
    const data: useBasicQuery = useStaticQuery(
        graphql`
            query {
                wp {
                    general {
                        acf {
                            readMore
                            price
                            buyNow
                            event
                            freeTraining
                            price
                        }
                    }
                    survey {
                        acf {
                            introText
                            answerAllText
                            sendButtonText
                            successText
                            errorText
                            completedText
                            questions {
                                question
                                type
                            }
                        }
                    }
                    contact {
                        acf {
                            callUsText
                            email
                            telephone
                            writeToUsText
                        }
                    }
                    payment {
                        acf {
                            orderErrorText
                            paymentErrorText
                            paymentSuccessfullText
                        }
                    }
                    singleproduct {
                        acf {
                            advertisements {
                                header
                                text
                            }
                            adImage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            layout: FULL_WIDTH
                                            placeholder: NONE
                                        )
                                    }
                                }
                            }
                            remainingGadgetText
                            remainingTrainingText
                            extraMaterials
                            instructors
                            socialsShare
                        }
                    }
                    forms {
                        acf {
                            addressLabel
                            addressPlaceholder
                            cityLabel
                            cityPlaceholder
                            emailLabel
                            emailPlaceholder
                            firmLabel
                            firmPlaceholder
                            nameLabel
                            namePlaceholder
                            nipLabel
                            nipPlaceholder
                            postalLabel
                            postalPlaceholder
                            surnameLabel
                            surnamePlaceholder
                            telephoneLabel
                            telephonePlaceholder
                        }
                    }
                    page404 {
                        acf {
                            header
                            text
                            buttonText
                            link
                        }
                    }
                }
            }
        `
    )
    return data.wp
}

export interface useBasicQuery {
    wp: {
        general: {
            acf: {
                readMore: string
                price: string
                buyNow: string
                event: string
                freeTraining: string
            }
        }
        survey: {
            acf: {
                introText: string
                answerAllText: string
                successText: string
                errorText: string
                sendButtonText: string
                completedText: string
                questions: Array<{
                    question: string
                    type: "radio" | "descriptive"
                }>
            }
        }
        contact: {
            acf: {
                callUsText: string
                email: string
                telephone: string
                writeToUsText: string
            }
        }
        payment: {
            acf: {
                orderErrorText: string
                paymentErrorText: string
                paymentSuccessfullText: string
            }
        }
        singleproduct: {
            acf: {
                advertisements: Array<{
                    header: string
                    text: string
                }>
                adImage: gatsbyImage
                remainingGadgetText: string
                remainingTrainingText: string
                extraMaterials: string
                instructors: string
                socialsShare: string
            }
        }
        forms: {
            acf: {
                addressLabel: string
                addressPlaceholder: string
                cityLabel: string
                cityPlaceholder: string
                emailLabel: string
                emailPlaceholder: string
                firmLabel: string
                firmPlaceholder: string
                nameLabel: string
                namePlaceholder: string
                nipLabel: string
                nipPlaceholder: string
                postalLabel: string
                postalPlaceholder: string
                surnameLabel: string
                surnamePlaceholder: string
                telephoneLabel: string
                telephonePlaceholder: string
            }
        }
        page404: {
            acf: {
                header: string
                text: string
                buttonText: string
                link: string
            }
        }
    }
}
