import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import Spinner from "./Spinner"

import Cart from "@icons/cart.svg"
import BlueArrow from "@icons/blue-arrow.svg"
import WhiteArrow from "@icons/white-arrow.svg"
import { breakpoints } from "@breakpoints"

const StyledArrow = styled.div`
    margin-left: 20px;

    svg {
        width: 10px;
    }
`

const StyledCart = styled.div`
    margin-left: 20px;

    svg {
        width: 20px;
    }
`

const buttonStyles = css`
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: var(--base-font);

    padding: 8px 15px;
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
        transform: scale(1.03);
    }

    ${breakpoints.lg} {
        font-size: 15px;
        padding: 12px 25px;
    }
`

const StyledInternalLink = styled(props => <Link {...props} />)`
    ${buttonStyles}
    color: ${({ $outline }) => ($outline ? "var(--medium-blue)" : "#fff")};
    border: ${({ $outline }) =>
        $outline ? "2px solid var(--medium-blue)" : "none"};
    background-color: ${({ $outline }) =>
        $outline ? "none" : "var(--bronze)"};
    display: ${({ $fullWidth }) => ($fullWidth ? "flex" : "inline-flex")};
    justify-content: ${({ $fullWidth }) =>
        $fullWidth ? "space-between" : "flex-start"};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`
const StyledExternalLink = styled.a<styledProps>`
    ${buttonStyles}
    color: ${({ outline }) => (outline ? "var(--medium-blue)" : "#fff")};
    border: ${({ outline }) =>
        outline ? "2px solid var(--medium-blue)" : "none"};
    background-color: ${({ outline }) => (outline ? "none" : "var(--bronze)")};
    display: ${({ fullWidth }) => (fullWidth ? "flex" : "inline-flex")};
    justify-content: ${({ fullWidth }) =>
        fullWidth ? "space-between" : "flex-start"};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`

const StyledCommonButton = styled.button<styledProps>`
    ${buttonStyles}
    color: ${({ outline }) => (outline ? "var(--medium-blue)" : "#fff")};
    border: ${({ outline }) =>
        outline ? "2px solid var(--medium-blue)" : "none"};
    background-color: ${({ outline }) => (outline ? "none" : "var(--bronze)")};
    display: ${({ fullWidth }) => (fullWidth ? "flex" : "inline-flex")};
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
    justify-content: ${({ fullWidth }) =>
        fullWidth ? "center" : "flex-start"};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`

export const Button = ({
    children,
    icon,
    href,
    outline,
    external,
    fullWidth,
    disabled,
    isLink,
    onClick,
    cypressId,
    loading,
}: props) => {
    const selectedIcon = () => {
        if (icon) {
            if (outline) {
                return (
                    <StyledArrow>
                        <BlueArrow />
                    </StyledArrow>
                )
            } else if (icon === "cart") {
                return (
                    <StyledCart>
                        <Cart />
                    </StyledCart>
                )
            } else {
                return (
                    <StyledArrow>
                        <WhiteArrow />
                    </StyledArrow>
                )
            }
        } else {
            return null
        }
    }
    if (isLink) {
        if (external) {
            return (
                <StyledExternalLink
                    disabled={disabled}
                    outline={outline}
                    href={href}
                    fullWidth={fullWidth}
                    data-cy={cypressId}
                >
                    {children}
                    {loading ? (
                        <div className="ml-4">
                            <Spinner small white={!outline} />
                        </div>
                    ) : (
                        selectedIcon()
                    )}
                </StyledExternalLink>
            )
        } else {
            return (
                <StyledInternalLink
                    disabled={disabled}
                    $outline={outline}
                    to={href}
                    $fullWidth={fullWidth}
                    data-cy={cypressId}
                >
                    {children}
                    {loading ? (
                        <div className="ml-4">
                            <Spinner small white={!outline} />
                        </div>
                    ) : (
                        selectedIcon()
                    )}
                </StyledInternalLink>
            )
        }
    } else {
        return (
            <StyledCommonButton
                disabled={disabled}
                outline={outline}
                fullWidth={fullWidth}
                onClick={onClick}
                data-cy={cypressId}
            >
                {children}
                {loading ? (
                    <div className="ml-4">
                        <Spinner small white={!outline} />
                    </div>
                ) : (
                    selectedIcon()
                )}
            </StyledCommonButton>
        )
    }
}

interface props {
    disabled?: boolean
    children: React.ReactNode
    isLink?: boolean
    icon?: "arrow" | "cart"
    href?: string
    outline?: boolean
    fullWidth?: boolean
    external?: boolean
    onClick?: () => void
    cypressId?: string
    loading?: boolean
}

interface styledProps {
    fullWidth?: boolean
    outline?: boolean
    disabled?: boolean
}
