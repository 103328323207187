import React from "react"
import styled from "styled-components"
import { Header } from "./styled"

const StyledCallUs = styled.span`
    margin-top: 20px;
    display: block;
`

const StyledTel = styled.a`
    display: block;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: var(--big-font);
`

const StyledEmail = styled.a`
    display: block;
    color: var(--light-blue);
`

const Contact = ({ header, callUs, telephone, writeToUs, email }: props) => {
    return (
        <>
            <Header dangerouslySetInnerHTML={{ __html: header }} />
            <StyledCallUs dangerouslySetInnerHTML={{ __html: callUs }} />
            <StyledTel
                href={`tel:${telephone}`}
                dangerouslySetInnerHTML={{ __html: telephone }}
            />
            <span dangerouslySetInnerHTML={{ __html: writeToUs }} />
            <StyledEmail
                href={`mailto:${email}`}
                dangerouslySetInnerHTML={{ __html: email }}
            />
        </>
    )
}

export default Contact

interface props {
    header: string
    callUs: string
    telephone: string
    writeToUs: string
    email: string
}
