import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { breakpoints } from "@breakpoints"
import { useQuery } from "./useQuery"
import { useBasicQuery } from "@useBasicQuery"
import Logo from "@icons/logo.svg"

import CopyrightBar from "./CopyrightBar"
import Menu from "./Menu"
import { GridContainer } from "../../styled/GridContainer"
import Socials from "./Socials"
import EmergencyButtons from "./EmergencyButtons"
import Contact from "./Contact"

const Wrapper = styled.footer`
    background-color: var(--dark-blue);
    color: #fff;
    font-size: var(--base-font);
`

const WrapperInner = styled.div`
    padding: 40px 0;

    ${breakpoints.md} {
        padding: 60px 0;
    }
`
const StyledLogo = styled.div`
    margin-bottom: 40px;

    svg {
        width: 170px;
        height: auto;

        ${breakpoints.md} {
            width: 200px;
        }
    }
`

const StyledMenusWrapper = styled.div`
    display: flex;
`
const Footer = () => {
    const {
        footer: {
            acf: {
                copyright,
                emergencyHeader,
                emergencyNumber,
                fastContact,
                linksHeader,
                linksMenu,
                menu,
                menuHeader,
                socialsHeader,
            },
        },
        socials: {
            acf: { facebook, instagram, twitter, youtube },
        },
    } = useQuery()

    const {
        contact: {
            acf: { email, telephone, writeToUsText, callUsText },
        },
    } = useBasicQuery()
    return (
        <Wrapper>
            <WrapperInner>
                <GridContainer>
                    <div className="grid md:grid-cols-3 gap-10">
                        <div>
                            <StyledLogo>
                                <Link
                                    aria-label="Link do strony głównej"
                                    to="/"
                                >
                                    <Logo />
                                </Link>
                            </StyledLogo>

                            <Contact
                                header={fastContact}
                                callUs={callUsText}
                                email={email}
                                telephone={telephone}
                                writeToUs={writeToUsText}
                            />
                        </div>
                        <StyledMenusWrapper>
                            <Menu items={menu} header={menuHeader} />
                            <Menu items={linksMenu} header={linksHeader} />
                        </StyledMenusWrapper>
                        <div>
                            <Socials
                                facebook={facebook}
                                twitter={twitter}
                                youtube={youtube}
                                instagram={instagram}
                                header={socialsHeader}
                            />
                            <EmergencyButtons
                                header={emergencyHeader}
                                numbers={emergencyNumber}
                            />
                        </div>
                    </div>
                </GridContainer>
            </WrapperInner>

            <CopyrightBar text={copyright} />
        </Wrapper>
    )
}

export default Footer
