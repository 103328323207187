import React from "react"
import styled from "styled-components"

const Spinner = ({ big, center, white, small }: props) => (
    <StyledSpinner
        center={center}
        big={big}
        white={white}
        small={small}
        viewBox="0 0 50 50"
    >
        <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
        />
    </StyledSpinner>
)

const StyledSpinner = styled.svg<props>`
    animation: rotate 2s linear infinite;
    margin: ${({ center }) => (center ? "0 auto" : "none")};
    width: ${({ big, small }) => (big ? "70px" : small ? "20px" : "30px")};
    height: ${({ big, small }) => (big ? "70px" : small ? "20px" : "30px")};

    & .path {
        stroke: ${({ white }) => (white ? "#fff" : "var(--light-blue)")};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`

export default Spinner

interface props {
    big?: true
    center?: true
    white?: boolean
    small?: true
}
