import React, { useContext } from "react"
import { UserContext } from "../../context/UserContext"
import { ModalContext } from "../../context/ModalContext"
import { Link } from "gatsby"
import { routes } from "@routes"
import Cart from "@icons/cart.svg"
import { breakpoints } from "@breakpoints"

import styled from "styled-components"

const StyledCart = styled(props => <Cart {...props} />)`
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;

    ${breakpoints.md} {
        margin-right: 30px;
        margin-left: 0;
    }
`

const StyledOrdersNumber = styled.span`
    position: absolute;
    bottom: -5px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--bronze);
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: var(--small-font);
    font-weight: 700;
`

const CartButton = () => {
    const { userData, userOrder } = useContext<any>(UserContext)
    const { setModalActive, setNavigateAfterClosing } =
        useContext<any>(ModalContext)

    return (
        <>
            {userData ? (
                <Link to={routes.cart}>
                    <StyledCart />
                    <StyledOrdersNumber>
                        {userOrder && userOrder.line_items
                            ? userOrder.line_items.length
                            : 0}
                    </StyledOrdersNumber>
                </Link>
            ) : (
                <div
                    onClick={() => {
                        setNavigateAfterClosing({ href: routes.cart })
                        setModalActive(true)
                    }}
                >
                    <StyledCart />
                </div>
            )}
        </>
    )
}

export default CartButton
