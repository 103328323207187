import React, { useEffect, useRef, useContext } from "react"
import styled from "styled-components"
import { ModalContext } from "../context/ModalContext"
import { breakpoints } from "@breakpoints"
import Close from "@icons/close.svg"

const StyledWrapper = styled.div<{ active: boolean }>`
    display: flex;
    pointer-events: ${({ active }) => (active ? "auto" : "none")};
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    z-index: ${({ active }) => (active ? 9999 : -9999)};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    visibility: ${({ active }) => (active ? "visible" : "none")};
    background-color: rgba(0, 0, 0, 0.6);
    opacity: ${({ active }) => (active ? "1" : 0)};
`

const StyledInner = styled.div<{ active: boolean }>`
    width: 90vw;
    padding: 20px;
    min-height: 500px;

    background-color: #fff;
    transform: ${({ active }) =>
        active ? "translateY(0)" : "translateY(-100px)"};
    transition: 0.3s ease-in-out;
    margin: 50px 0;

    ${breakpoints.md} {
        padding: 50px;
        width: 600px;
    }
`

const StyledClose = styled.div`
    cursor: pointer;

    svg {
        margin-left: auto;
        width: 20px;
        height: 20px;
        fill: var(--dark-blue);
    }
`

const Modal = ({ children }: { children: React.ReactNode }) => {
    const { modalActive, setModalActive } = useContext<any>(ModalContext)
    const ref = useRef(null)

    const clickListener = (e: MouseEvent) => {
        if (!(ref.current! as any).contains(e.target)) {
            setModalActive(false)
        }
    }

    useEffect(() => {
        if (modalActive) {
            document.addEventListener("mousedown", clickListener)
        }
        return () => {
            document.removeEventListener("mousedown", clickListener)
        }
    }, [modalActive])
    return (
        <StyledWrapper active={modalActive}>
            <StyledInner ref={ref} active={modalActive}>
                <StyledClose onClick={() => setModalActive(false)}>
                    <Close />
                </StyledClose>
                {modalActive && <>{children}</>}
            </StyledInner>
        </StyledWrapper>
    )
}

export default Modal
