import React, { useContext, Fragment } from "react"
import { ModalContext } from "../../context/ModalContext"
import { UserContext } from "../../context/UserContext"
import styled, { css } from "styled-components"
import { Link, navigate } from "gatsby"
import { breakpoints } from "@breakpoints"
import ProfileButton from "./ProfileButton"
import { routes } from "@routes"

const Wrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 999;
    padding: 20px;
    height: 0px;
    left: 0;
    top: 175px;
    width: 100%;
    background-color: var(--dark-blue);
    transition: 0.5s ease-in-out;
    transition-delay: 0.2s;
    opacity: 0;
    pointer-events: none;
    overflow: auto;

    &.active {
        opacity: 1;
        height: calc(100vh - 174px);
        pointer-events: auto;
    }

    ${breakpoints.md} {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: static;
        height: auto;
        flex-direction: row;
        opacity: 1;
        pointer-events: auto;
        background-color: transparent;
    }
`

const linkStyles = css`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    text-transform: uppercase;
    background-color: none;
    border-radius: 10px;
    transition: 0.3s;
    font-weight: 600;
    letter-spacing: 0.1em;
    padding: 0;
    margin-bottom: 30px;
    font-size: var(--base-font);
    cursor: pointer;

    ${breakpoints.md} {
        margin: 0 20px;
        text-align: center;
    }

    ${breakpoints.lg} {
        font-size: var(--medium-font);
        margin: 0 25px;
    }

    &:hover,
    &.active {
        color: var(--light-blue);
    }

    &.active {
        font-weight: "800";
    }

    &:after {
        content: attr(data-text) / "";
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: 800;
    }
`

const LockedLink = styled.div`
    ${linkStyles}
`

const Styledlink = styled(props => <Link {...props} />)`
    ${linkStyles}
    text-transform: ${({ $button }) => ($button ? "capitalize" : "uppercase")};
    background-color: ${({ $button }) =>
        $button ? "var(--medium-blue)" : "none"};

    font-weight: ${({ $button }) => ($button ? "700" : "600")};
    letter-spacing: ${({ $button }) => ($button ? "0.05em" : "0.1em")};
    padding: ${({ $button }) => ($button ? " 10px 20px" : "0")};

    &:hover,
    &.active {
        color: ${({ $button }) => ($button ? "#fff" : "var(--light-blue)")};
        transform: ${({ $button }) => ($button ? "scale(1.03)" : "")};
    }

    &.active {
        font-weight: ${({ $button }) => ($button ? "700" : "800")};
    }
`

const Menu = ({ menuItems, active }: props) => {
    const { setNavigateAfterClosing } = useContext<any>(ModalContext)
    const { userData } = useContext<any>(UserContext)
    return (
        <Wrapper className={active ? "active" : ""}>
            {menuItems?.map(({ id, url, label }: item, index) => (
                <Fragment key={id}>
                    {url === routes.knowledgeBase && !userData ? (
                        <LockedLink
                            onClick={() => {
                                setNavigateAfterClosing({
                                    href: routes.knowledgeBase,
                                })
                                navigate(routes.knowledgeBaseLocked)
                            }}
                            dangerouslySetInnerHTML={{ __html: label }}
                        />
                    ) : (
                        <Styledlink
                            activeClassName="active"
                            partiallyActive
                            to={url}
                            $button={index === menuItems.length - 1}
                            data-text={label}
                            onClick={() =>
                                document.body.classList.remove("body-overflow")
                            }
                        >
                            {label}
                        </Styledlink>
                    )}
                </Fragment>
            ))}
            <ProfileButton mobile />
        </Wrapper>
    )
}

export default Menu

interface item {
    id: string
    url: string
    label: string
}

interface props {
    menuItems: Array<item>
    active: boolean
}
